/* source: https://levelup.gitconnected.com/build-an-accessible-switch-component-with-react-and-typescript-d455a405aaa */
.switch {
	display: flex;
	align-items: flex-start;
	gap: 4px;

	input {
		appearance: none;
		min-width: 48px;
		min-height: 15px;
		margin: 0;

		position: relative;
		background-color: #A1A1A1;
		border-radius: 32px;
		border: 1px solid #A1A1A1;

		cursor: pointer;
		user-select: none;
		transition: background-color 0.3s,
		border-color 0.3s;

		&:checked {
			background-color: #222222;
			border-color: #222222;

			&::before {
				left: unset;
				transform: translateX(calc(100% + 22px));
			}

			&::after {
				content: attr(data-on);
				left: 0.4em;
				right: unset;
			}
		}

		&::before {
			content: '';
			position: absolute;
			height: 11px;
			width: 11px;
			top: 1px;
			left: 2px;
			bottom: 1px;
			background-color: white;
			transition: all 0.3s;
			border-radius: 50%;
			cursor: pointer;
			z-index: 1;
		}

		&::after {
			content: attr(data-off);
			color: white;
			display: block;
			position: absolute;
			font-size: 8px;
			line-height: 1;
			top: 0.3em;
			right: 0.4em;
			bottom: 0.5em;
		}

		//&:focus {
		//	outline: 2px solid #222222;
		//	outline-offset: 2px;
		//}
	}

	&-labels {
		display: flex;
		flex-direction: column;
		gap: 8px;

		span {
			cursor: pointer;
			line-height: 1.4;
			font-size: 16px;
			user-select: none;
		}

		p {
			font-size: 12px;
			letter-spacing: 0.4px;
			line-height: 1.3;
			margin: 0;
			color: #555555;
		}
	}
}
